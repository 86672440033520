import { LegacyRef, forwardRef } from 'react';
import { IButtonDefaultProps } from './button.model';
import './button.scss';
import RainbowTransparentIcon from '../../icons/rainbow-transparent.icon';

export const ButtonComponent = (
	{
		children,
		className = '',
		onClick,
		link,
		type = 'button',
		disabled,
		theme = 'default',
		radius = 40,
		...props
	}: IButtonDefaultProps,
	ref: LegacyRef<HTMLButtonElement> | undefined,
) => {
	return (
		<button
			ref={ref}
			className={`button ${theme} ${className} radius_${radius}`}
			onClick={onClick}
			type={type}
			disabled={disabled}
			{...props}
		>
			<>
				{theme === 'rainbow-transparent' && <RainbowTransparentIcon />}

				{theme === 'rainbow' ? <span>{children}</span> : children}
			</>
		</button>
	);
};

export const Button = forwardRef(ButtonComponent);
