import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { RoutsPath } from '../../../@types';
import { usePagesLoad } from 'src/components/loader/loader.context';
import { InSlugIcon, ToSlugIcon } from 'src/components/ui/icons';
import { Button } from '../../ui/buttons/button/button.component';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useWindowLoader } from 'src/hooks/useWindowLoader';
import { isSupportWebp } from '../../../utils/check.utils';
import { createLangHref } from '../../../utils/href.utils';
import { loadImage } from 'src/utils/image.utils';
import layer1 from './assets/layer-1.jpg';
import layer1w from './assets/layer-1.webp';
import layer2 from './assets/layer-2.png';
import layer2w from './assets/layer-2.webp';
import layer3 from './assets/layer-3.png';
import layer3w from './assets/layer-3.webp';
import './section-animation-3d.scss';

export const SectionAnimation3d = () => {
	const { t, i18n } = useTranslation();
	const navigation = useNavigate();
	const { hideLoader, showLoader, isAwaitLoader } = useWindowLoader();
	const { pagesLoad, onSetPageFirstRender } = usePagesLoad();
	const canvasContainer = useRef<HTMLDivElement | null>(null);
	const { width = window.innerWidth } = useWindowSize();
	const isWebp = isSupportWebp();
	const { ref, inView } = useInView({
		threshold: 0,
	});
	const timerID = useRef<NodeJS.Timeout | null>(null);
	const svgOneRef = useRef<SVGSVGElement | null>(null);
	const swgTwoRef = useRef<SVGSVGElement | null>(null);
	const textRef = useRef<HTMLParagraphElement | null>(null);
	const layerOneRef = useRef<HTMLDivElement | null>(null);
	const layerTwoRef = useRef<HTMLDivElement | null>(null);
	const layerBgRef = useRef<HTMLDivElement | null>(null);
	const isViewRef = useRef(true);

	useEffect(() => {
		isViewRef.current = inView;
	}, [inView]);

	const onMouseMove = useCallback(
		(e: MouseEvent) => {
			if (width > 1280 && isViewRef.current === true) {
				Object.assign(document.documentElement, {
					style: `
                    --move-x: ${
											(e.clientX - window.innerWidth / 2) * -0.005
										}deg;
                    --move-y: ${(e.clientY - window.innerHeight / 2) * 0.01}deg;
                    `,
				});
			}
		},
		[width],
	);

	useEffect(() => {
		const imgLayer1 = isWebp ? layer1w : layer1;
		const imgLayer2 = isWebp ? layer2w : layer2;
		const imgLayer3 = isWebp ? layer3w : layer3;

		if (!pagesLoad.home && !isAwaitLoader) {
			showLoader();
		}

		Promise.all([
			loadImage(imgLayer1),
			loadImage(imgLayer2),
			loadImage(imgLayer3),
		])
			.then(() => {
				if (
					textRef.current &&
					layerOneRef.current &&
					layerTwoRef.current &&
					svgOneRef.current &&
					swgTwoRef.current &&
					layerBgRef.current
				) {
					textRef.current.classList.add('active');
					svgOneRef.current.classList.add('active');
					swgTwoRef.current.classList.add('active');
					layerTwoRef.current.classList.add('active');
					layerOneRef.current.classList.add('active');
					layerBgRef.current.classList.add('active');
				}

				timerID.current = setTimeout(() => {
					document.addEventListener('mousemove', onMouseMove);
				}, 2000);

				if (pagesLoad.home === false) {
					hideLoader();
					onSetPageFirstRender('home', true);
				}
			})
			.catch((e) => {
				console.log('Error load layers: ', JSON.stringify(e));
			});

		return () => {
			if (timerID.current) {
				clearTimeout(timerID.current);
			}
			document.removeEventListener('mousemove', onMouseMove);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const goToSchedule = () =>
		navigation(createLangHref(i18n.language, RoutsPath.schedule));

	return (
		<div className="container-animation" ref={ref}>
			<div className="layers__item layer-3">
				<div className="container position_relative">
					<Button
						theme="rainbow-transparent"
						className={`layers__button ${i18n.language === 'ru' ? 'layers__button--ru' : ''}`}
						onClick={goToSchedule}
					>
						<span>{t('btn.book-first-section')}</span>
					</Button>
					<InSlugIcon className="layers__svg left" refComponent={svgOneRef} />
					<ToSlugIcon className="layers__svg right" refComponent={swgTwoRef} />
					<p className="layers__text" ref={textRef}>
						{t('home.space.connect')} <br />
						{t('home.with.yourself')}
					</p>
				</div>
			</div>

			<section className="layers">
				<div className="layers__container">
					<div
						ref={layerBgRef}
						className="layers__item layer-1"
						style={{ backgroundImage: `url(${isWebp ? layer1w : layer1})` }}
					></div>
					<div
						ref={layerOneRef}
						className="layers__item layer-2"
						style={{ backgroundImage: `url(${isWebp ? layer2w : layer2})` }}
					></div>
					<div
						ref={layerTwoRef}
						className="layers__item layer-5"
						style={{ backgroundImage: `url(${isWebp ? layer3w : layer3})` }}
					></div>
				</div>
			</section>
			<div className="falling-leaves" ref={canvasContainer}></div>
		</div>
	);
};
