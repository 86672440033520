import { useEffect, useRef, useState } from 'react';
import { ISvgIconProps } from '.';

const generateUniqueId = () => {
	return 'id-' + Math.random().toString(36).slice(2, 9);
};

const RainbowTransparentIcon = ({
																	className = '',
																}: ISvgIconProps) => {
	const uniqueId = generateUniqueId();
	const svgRef = useRef<SVGSVGElement>(null);
	const [svgDimensions, setSvgDimensions] = useState({ width: 220, height: 80 });

	useEffect(() => {
		const handleResize = () => {
			if (svgRef.current && svgRef.current.parentElement) {
				const parentWidth = svgRef.current.parentElement.offsetWidth;
				const parentHeight = svgRef.current.parentElement.offsetHeight;
				setSvgDimensions({ width: parentWidth, height: parentHeight });
			}
		};

		const observer = new ResizeObserver(() => {
			handleResize();
		});

		if (svgRef.current && svgRef.current.parentElement) {
			observer.observe(svgRef.current.parentElement);
		}

		return () => {
			if (svgRef.current && svgRef.current.parentElement) {
				observer.unobserve(svgRef.current.parentElement);
			}
		};
	}, [svgRef]);

	return (
		<svg
			className={className}
			ref={svgRef}
			viewBox={`0 0 ${svgDimensions.width} ${svgDimensions.height}`}
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="xMidYMid meet"
			width='100%'
			height="100%"
		>
			<defs>
				<linearGradient id={uniqueId} x1="0%" y1="0%" x2="100%" y2="0%">
					<stop offset="0%" stopColor="rgba(64, 128, 255, 1)" />
					<stop offset="49%" stopColor="rgba(244, 96, 230, 1)" />
					<stop offset="100%" stopColor="rgba(255, 226, 2, 1)" />
					<animate attributeName="x1" values="0%;0%;100%;100%;0%" dur="6s" repeatCount="indefinite" />
					<animate attributeName="y1" values="0%;100%;100%;0%;0%" dur="6s" repeatCount="indefinite" />
					<animate attributeName="x2" values="100%;100%;0%;0%;100%" dur="6s" repeatCount="indefinite" />
					<animate attributeName="y2" values="0%;0%;100%;100%;0%" dur="6s" repeatCount="indefinite" />
				</linearGradient>
			</defs>

			<rect
				x="2"
				y="2"
				width={`calc(${svgDimensions.width}px - 4px)`}
				height={`calc(${svgDimensions.height}px - 4px)`}
				rx="10"
				ry="10"
				fill="none"
				stroke={`url(#${uniqueId})`}
				strokeWidth="3"
			/>
		</svg>
	);
};

export default RainbowTransparentIcon;
